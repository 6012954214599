module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rezidence Sobín","short_name":"Sobín","start_url":"/","background_color":"#ffffff","theme_color":"#367ca4","display":"browser","icon":"/vercel/path0/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b490b34721d458d24698fd546f1fc717"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
