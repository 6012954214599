exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-domy-1-js": () => import("./../../../src/pages/domy/1.js" /* webpackChunkName: "component---src-pages-domy-1-js" */),
  "component---src-pages-domy-2-js": () => import("./../../../src/pages/domy/2.js" /* webpackChunkName: "component---src-pages-domy-2-js" */),
  "component---src-pages-domy-3-js": () => import("./../../../src/pages/domy/3.js" /* webpackChunkName: "component---src-pages-domy-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-projektu-js": () => import("./../../../src/pages/o-projektu.js" /* webpackChunkName: "component---src-pages-o-projektu-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-flat-js": () => import("./../../../src/templates/flat.js" /* webpackChunkName: "component---src-templates-flat-js" */),
  "component---src-templates-flat-level-1-js": () => import("./../../../src/templates/flat-level1.js" /* webpackChunkName: "component---src-templates-flat-level-1-js" */),
  "component---src-templates-flat-level-2-js": () => import("./../../../src/templates/flat-level2.js" /* webpackChunkName: "component---src-templates-flat-level-2-js" */)
}

